// src/Tracking.js
import React, { useState } from 'react';
import { default as axios } from 'axios';

const Tracking = () => {
  const [inputValue, setInputValue] = useState('');
  const [trackingUrl, setTrackingUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e) => {
    let value = e.target.value;

    // Remove all non-digit characters
    value = value.replace(/\D/g, "");

    // Add the dots and dash for CPF format
    if (value.length <= 11) {
      value = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    }

    setInputValue(value);
  };

  const fetchOrderDetails = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Set loading state to true

    try {
      const response = await axios.get(`https://apse-tracking.onrender.com/getOrderDetails/${inputValue}`);
      // const response = await axios.get(`http://localhost:3000/getOrderDetails/${inputValue}`);
      
      const trackingUrl = response.data;
  
      setTrackingUrl(trackingUrl);
    } catch (error) {
      setTrackingUrl("Pedido não encontrado ou ainda não foi enviado para a transportadora.")
    } finally {
      setIsLoading(false); // Reset loading state
    }
  };

  const navigateToUrl = (url) => {
    window.location.href = url;
  };


  return (
    <main className="grid min-h-screen place-items-center bg-white px-6 py-24
     lg:px-8 	">
      <div className="text-center">
        <img className="mx-auto" src="https://www.apsecosmetics.com.br/cdn/shop/files/Logo_200x@2x.png?v=1674161462" />
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Faça o rastreio do seu pedido</h1>
        <p className="mt-6 text-base leading-7 text-gray-600">Digite o seu CPF para buscar</p>
        <form onSubmit={fetchOrderDetails} className="p-6 bg-white rounded shadow-md">
          <input
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            placeholder="XXX.XXX.XXX-XX"
            className="p-2 border rounded w-full mb-4"
            maxLength="14"
          />
          <button 
            type="submit" 
            className={`bg-green-900 text-white p-2 rounded w-full ${!inputValue.trim() ? 'cursor-not-allowed' : ''}`} 
            disabled={isLoading || !inputValue.trim()}
          >
            {isLoading ? 'Carregando...' : 'Pesquisar'}
          </button>
        </form>
      </div>

      {Array.isArray(trackingUrl) && trackingUrl.length ?
        <div>
          <ul role="list" className="divide-y divide-gray-100 border p-5 boder-solid border-green-900 rounded-md mt-10">
            {trackingUrl.map((order, index) => {
              if (order.link_rastreio !== null) {
                return (
                  <li key={index} className="relative flex justify-between gap-x-6 py-5" onClick={() => navigateToUrl(order.link_rastreio)}>
                    <div className="flex min-w-0 gap-x-4">
                      <div className="min-w-0 flex-auto">
                        <p className="text-sm font-semibold leading-6 text-gray-900">
                          <a href="#">
                            <span className="absolute inset-x-0 -top-px bottom-0"></span>
                            <p className="text-sm leading-6 text-gray-900">Pedido {order.cod_pedido}</p>
                          </a>
                        </p>
                      </div>
                    </div>
                    <div className="flex shrink-0 items-center gap-x-4">
                      <div className=" sm:flex sm:flex-col sm:items-end">
                        <p className="mt-1 text-xs leading-5 text-green-900">Ver Rastreio</p>
                      </div>
                      <svg className="h-5 w-5 flex-none text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
                      </svg>
                    </div>
                  </li>
                )
              }
            })}
          </ul>
        </div>
        :
        <div>
          <p> {trackingUrl}</p>
        </div>
      }
    </main >

   
  );
};

export default Tracking;
